<template>
  <b-modal size="md" 
    id="modalReference" 
    :title="$t('Número de referencia courier')"
    centered
    no-close-on-esc no-close-on-backdrop
    @ok="ok"
    ok-variant="warning" 
    :ok-title="$t('Actualizar')" 
    :ok-disabled="loading.reference"
    ok-only>
      <b-card>
        <form-render :fields="fields" :form.sync="form" @send="updateReference"
          ref="formRenderUpdateReferenceProvider"
          containerButtonsClass="col-sm-12 col-lg-4 container-button">
        </form-render>
      </b-card>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import MaterialsService from './materials.service'

export default {
  props: ['selectedMaterial'],
  data () {
    return {
      fields: [],
      form: {},
      loading: {
        reference: false
      },
      materialsService: new MaterialsService(this)
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    selectedMaterial () {
      this.form = {
        reference: this.selectedMaterial.reference,
        material_id: this.selectedMaterial.material_id
      }
    }
  },
  mounted () {
    this.getAllInitialData()
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.fields = [
        {
          fieldType: 'FieldInput', 
          name: 'reference', 
          type: 'text', 
          label: 'Número de referencia courier', 
          clearable: true, 
          validation: 'required',
          containerClass: 'col-md-12 container-info'
        }
      ]
    },
    ok (e) {
      e.preventDefault()
      this.$refs.formRenderUpdateReferenceProvider.checkForm()
    },
    updateReference () {
      const { first_name, last_name, email, role } = this.mySession
      const { reference, material_id } = this.form
      const queryParams = {
        user: {
          name: `${first_name} ${last_name}`,
          email,
          role
        },
        reference
      }
      this.loading.reference = true
      this.materialsService.callService('updateReferenceCarrier', queryParams, { material_id })
        .then(resp => this.$emit('send', resp.data))
        .catch(err => this.$alert(err))
        .finally(() => this.loading.reference = false)
    }
  }
}
</script>

<style>

</style>