<template>
  <b-modal size="lg" id="modalDetails" :title="$t('Materiales Solicitados')" hide-footer centered>
    <b-card>
        <div class="col-md-12" v-if="selectedMaterial.id">
          <!-- Primera fila -->
          <div class="row mb-2">
            <div class="col-md-4">
              <label><b>{{$t('Usuario')}}</b></label>
              <div class="ml-1"> {{ selectedMaterial.creator_user.name || '--' }} </div>
            </div>
            <div class="col-md-4">
              <label><b>{{$t('Fecha de solicitud')}}</b></label>
              <div class="ml-1"> {{ selectedMaterial.created_at || '--' }} </div>
            </div>
          </div>
          <!-- Segunda fila -->
          <div class="row mb-2">
            <div class="col-md-4">
              <label><b>{{$t('Empresa')}}</b></label>
              <div class="ml-1"> {{ selectedMaterial.company.company_name_id || '--' }} </div>
            </div>
            <div class="col-md-4">
              <label><b>{{$t('Dirección de entrega')}}</b></label>
              <div class="ml-1"> {{ selectedMaterial.warehouse.address.full_address || '--' }} </div>
            </div>
          </div>
          <!-- Tercera fila -->
          <div class="row mb-2">
            <div class="col-md-4">
              <label><b>{{$t('Nombre de contacto')}}</b></label>
              <div class="ml-1"> {{ selectedMaterial.contact.name || '--' }} </div>
            </div>
            <div class="col-md-4">
              <label><b>{{$t('Teléfono de contacto')}}</b></label>
              <div class="ml-1"> {{ selectedMaterial.contact.phone || '--' }} </div>
            </div>
            <div class="col-md-4">
              <label><b>{{$t('Courier')}}</b></label>
              <div class="ml-1"> {{ selectedMaterial.provider.name || '--' }} </div>
            </div>
          </div>
          <hr>
          <!-- Listado de materiales -->
          <div class="row mt-2">
            <div class="col-md-4" v-for="(material, index) in selectedMaterial.materials" :key="index">
              <label><b>{{ material.label }}</b></label>
              <div class="ml-1"> {{ material.value || '--' }} </div>
            </div>
          </div>
        </div>
    </b-card>
  </b-modal>
</template>

<script>
export default {
  props: ['selectedMaterial']
}
</script>

<style>

</style>