<template>
  <div class="company-dialog">
    <b-modal
      id="modalExportMaterials" 
      centered
      modal-class="modal-warning"
      hide-footer
      size="lg"
      title="Exportar solicitudes de materiales">
        <div class="mt-0">
          <h5 class="my-2 text-center"><strong>Según el filtro aplicado tienes las siguientes opciones de descarga:</strong></h5>
          <ul>
            <li>
              <strong>
                Exportar formato de Courier:
              </strong> 
              <span>
                Este formato será distinto según el Courier seleccionado en la búsqueda avanzada.
              </span>
            </li>
            <li>
              <strong>
                Exportar formato normal:
              </strong>
              <span>
                Este formato es genérico.
              </span>
            </li>
          </ul>
          <div class="container my-2">
            <div class="row d-flex justify-content-around">
              <b-button class="col-md-5" 
                variant="warning" 
                :disabled="disable.customExport" 
                @click="downloadMaterials('custom')"> 
                  <div>
                    <feather-icon v-if="!loading.customExport" icon="TruckIcon" /> <i v-else :class="['fa', 'fa-spinner', 'fa-spin']"/>
                    <span> {{$t('export-materials-format-carrier')}} </span> 
                  </div>
              </b-button>
              <b-button class="col-md-5" 
                variant="success" 
                :disabled="disable.normalExport"
                @click="downloadMaterials('normal')"> 
                  <div>
                    <feather-icon v-if="!loading.normalExport" icon="DownloadIcon" /> <i v-else :class="['fa', 'fa-spinner', 'fa-spin']"/>
                    <span> {{$t('export-normal-materials')}} </span> 
                  </div>
              </b-button>
            </div>
          </div>

        </div>
    </b-modal>
  </div>
</template>

<script>
import MaterialsService from './materials.service'
export default {
  props: ['filters'],
  data () {
    return {
      loading: {
        normalExport: false,
        customExport: false
      },
      disable: {
        normalExport: false,
        customExport: false
      },
      exportMaterials: {
        classic: 'normal',
        custom: 'custom'
      },
      materialsService: new MaterialsService(this)
    }
  },
  methods: {
    /**
     * Función para exportar solicitudes de materiales
     * @param {String} format Parametro que indica el tipo de formato a exportar.
     */
    downloadMaterials(format) {
      if (format === this.exportMaterials.classic) {
        this.loading.normalExport = true
        this.disable.normalExport = true
        this.disable.customExport = true
      } else if (format === this.exportMaterials.custom) {
        this.loading.customExport = true
        this.disable.customExport = true
        this.disable.normalExport = true
      }

      if (format === this.exportMaterials.custom) this.filters.custom = true
      else delete this.filters.custom

      this.materialsService.callService('exportMaterials', this.filters, null)
        .then(resp => {
          if (resp.data) window.open(resp.data, '_blank')
          else this.$alert(this.$t('No se encontraron solicitudes de materiales para exportar'))
        })
        .catch(err => {
          console.error(err)
          this.$alert(this.$t('Ocurrió un problema al exportar el archivo'))
        })
        .finally(() => {
          this.loading.customExport = false
          this.disable.customExport = false

          this.loading.normalExport = false
          this.disable.normalExport = false
        })
    }
  }
}
</script>

<style scoped>

</style>