<template>
  <b-modal id="modalFilter" :title="$t('Filtro')" size="md" :ok-title="$t('Filtrar')" ok-variant="warning" ok-only
    no-close-on-esc no-close-on-backdrop centered @close="close" @ok="ok">
    <form-render :form.sync="materialFilters" :fields="fields" @send="onAccept" containerButtonsClass="col-sm-12"
      ref="formRenderMaterial">
    </form-render>
  </b-modal>
</template>

<script>
import MaterialsService from './materials.service'
import { mapGetters } from 'vuex'

export default {
  name: 'modalFilter',
  props: ['materialFilters'],
  data() {
    return {
      form: {},
      fields: [],
      keyFormRender: 0,
      status: [
        {
          id: 'requested',
          text: 'Solicitado'
        },
        {
          id: 'pending',
          text: 'Pendiente'
        },
        {
          id: 'delivered',
          text: 'Entregado'
        }
      ],
      ROLES : {
        marketplace: 'marketplace',
        ecommerce: 'ecommerce',
        seller: 'seller',
        admin: 'admin',
        superadmin: 'superadmin'
      },
      nameProvider: false,
      materialsService: new MaterialsService(this)
    }
  },
  watch: {
    mySession() {
      if (this.mySession.id) this.setInitialData()
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  mounted() {
    this.getAllInitialData()
  },
  methods: {
    // Función para inicializar la data
    getAllInitialData() {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData() {
      this.fields = [
        {
          fieldType: 'FieldSelect', 
          clearable: true, 
          label: 'País', 
          name: 'country',
          containerClass: 'col-sm-12 container-info', 
          addFlags: true
        },
        {
          fieldType: 'FieldInput', 
          type: 'text', 
          name: 'id_ref', 
          label: 'ID Envíame/Ref. Courier', 
          containerClass: 'col-sm-12 container-info',
          clearable: true
        },
        {
          fieldType: 'FieldDatepicker', 
          name: 'date_range', 
          label: 'Fecha de creación', 
          range: true, 
          containerClass: 'col-sm-12 container-info' 
        },
        {
          fieldType: 'FieldSelect', 
          name: 'organization', 
          label: 'Marketplace', 
          containerClass: 'col-sm-12 container-info', 
          searchOnType: { 
            fx: this.searchOrganizations, 
            nChars: 3, 
            debounce: 300, 
            allowSearchAsValue: true, 
            persistSearch: true 
          } 
        },
        {
          fieldType: 'FieldSelect', 
          name: 'shipper', 
          label: 'Empresa', 
          containerClass: 'col-sm-12 container-info', 
          searchOnType: { 
            fx: this.searchShippers, 
            nChars: 3, 
            debounce: 300, 
            allowSearchAsValue: true, 
            persistSearch: true 
          } 
        },
        {
          fieldType: 'FieldSelect', 
          name: 'carrier', 
          label: 'Courier', 
          containerClass: 'col-sm-12 container-info', 
          searchOnType: { 
            fx: this.getCarriers, 
            nChars: 3, 
            debounce: 300, 
            allowSearchAsValue: true, 
            persistSearch: true 
          }
        },
        {
          fieldType: 'FieldSelect', 
          name: 'status', 
          label: 'Estado', 
          containerClass: 'col-sm-12 container-info', 
          multiple: true,
          options: this.status 
        }
      ]

      this.customFormByRole()
    },
    customFormByRole () {
      // Para usuario Shipper y/o mkp se deben sacar ciertos fields del form
      if (this.mySession.role === this.ROLES.ecommerce || this.mySession.role === this.ROLES.seller) this.fields = this.fields.filter(field => !['country', 'shipper', 'organization'].includes(field.name))
      if (this.mySession.role === this.ROLES.marketplace) this.fields = this.fields.filter(field => !['country', 'organization'].includes(field.name))
      if (this.mySession.role === this.ROLES.superadmin || this.mySession.role === this.ROLES.admin) this.searchCountry()
    },
    /**
     * Función encargada de buscar el índice de un campo del formulario
     * @param {String} name Nombre del campo del formulario
     * @returns {Number} Índice del field a buscar
     */
    selectionIndex(name) {
      return this.fields.findIndex(el => el.name === name)
    },
    ok(e) {
      e.preventDefault()
      this.$refs.formRenderMaterial.checkForm()
    },
    close() {
      this.$emit('update:material', {})
    },
    onAccept(data) {
      const allFilters = { ...this.pickupFilters, ...data }
      this.$emit('send', allFilters)
      // this.formFilters = {}
    },
    searchShippers(value) {

      let service = 'getShippers'
      const params = {}
      if (this.mySession.role === 'marketplace') {
        service = 'getShippersByOrganizationSimplified'
        params.organization_id = this.mySession.organization.id
      }
      return this.materialsService.callService(service, {name: value}, params)
        .then(response => {
          return response.data.map(el => ({...el, text: `${el.id} - ${el.name1 ? el.name1 : el.name}`}))
        })
        .catch(error => console.error(error))
    },
    searchOrganizations (value) {
      return this.materialsService.callService('getOrganizations', { name: value }, null)
        .then(response => {
          return response.data.map(el => ({ ...el, text: `${el.id} - ${el.name}` }))
        })
        .catch(error => console.error(error))
    },
    getCarriers(value) {
      const queryParams = {
        materials_enabled: true,
        name: value
      }
      return this.materialsService.callService('getCarriers', queryParams)
        .then(response => response.data.map(el => ({ ...el, text: el.name, id: el.code })))
        .catch(error => console.error(error))
    },
    searchCountry () {
      return this.materialsService.callService('getCountries', null, null)
        .then(response => {
          let options = response.data.map(el => ({...el, text: `${el.name1 ? el.name1 : el.name}`}))
          const index = this.fields.findIndex(el => el.name === 'country')
          // Sacamos del array Argentina y Ecuador
          options = options.filter(country => !['AR', 'EC'].includes(country.code))
          this.fields[index].options = options
          this.keyFormRender++
        })
        .catch(error => console.error(error))
    }
  }

}
</script>

<style></style>