<template>
  <b-modal id="modalStatus" :title="$t(`${singleEditing ? 'Estados' : 'Actualización masiva de estados'}`)" size="sm" :ok-title="$t('Actualizar')" ok-variant="warning" 
  ok-only no-close-on-esc no-close-on-backdrop centered @ok="ok" :ok-disabled="loading">
    <form-render v-show="mySession.id" :form.sync="form" :fields="fields" @send="onAccept" containerButtonsClass="col-sm-12" ref="formRenderStatus">
      <template #message v-if="!singleEditing">
        <small>{{$t('La operación puede tomar varios minutos.')}}</small>
      </template>
      <template #btn-alert v-if="disableUpdateButton && singleEditing">
        <small class="text-danger">{{$t('Debe seleccionar un estado distinto al estado actual de la solicitud.')}}</small>
      </template>
    </form-render>
    <div v-show="!mySession.id">
      <b-skeleton width="30%"/>
      <b-skeleton type="input"/>
      <b-skeleton width="30%" class="mt-1"/>
      <b-skeleton type="input"/>
    </div>
  </b-modal>
</template>

<script>
import * as moment from 'moment'
import { mapGetters } from 'vuex'
import MaterialsService from './materials.service'

export default {
  name:'modalStatus',
  props: ['material', 'send'],
  data () {
    return {
      form: {},
      fields: [],
      status: [
        {
          id: 1,
          code: 'requested',
          text: 'Solicitado'
        },
        {
          id: 2,
          code: 'pending',
          text: 'Pendiente'
        },
        {
          id: 3,
          code: 'delivered',
          text: 'Entregado'
        },
        {
          id: 4,
          code: 'dismissed',
          text: 'Desestimado'
        }
      ],
      initialDate: moment(),
      singleEditing: false,
      loading: true,
      disableUpdateButton: false,
      keyFormRender: 0,
      materialsService: new MaterialsService(this)
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    mySession() {
      if (this.mySession.id) this.setInitialData()
    },
    'material.status.code' () {
      this.form = {
        date: this.initialDate,
        ...this.material
      }
      // Identificamos si es edición multiple o individual
      this.singleEditing = !!this.material.id
      this.keyFormRender++
    },
    status () {
      const index = this.fields.findIndex(el => el.name === 'status')
      this.fields[index].options = this.status
      this.loading = false
    },
    'form.status' () {
      if (this.material?.status?.code === this.form?.status?.code) {
        this.loading = true 
        this.disableUpdateButton = true
      } else {
        this.loading = false
        this.disableUpdateButton = false
      }
    }
  },
  mounted () {
    this.getAllInitialData() 
  },
  methods: {
    getAllInitialData() {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.fields = [ 
        {
          fieldType: 'FieldSelect', 
          name: 'status', 
          label: 'Estado', 
          containerClass: 'container-info col-sm-12', 
          options: this.status,  
          validation: 'required'
        },
        { 
          fieldType: 'FieldDatepicker', 
          label: 'Fecha cambio de estado', 
          name: 'date',
          containerClass: 'container-info col-sm-12'
        },
        {
          fieldType: 'FieldTextarea', 
          name: 'info', 
          label: 'Observación/comentario', 
          containerClass: 'container-info col-sm-12'
        },
        { 
          name: 'message', 
          useSlot: true, 
          containerClass: 'container-info col-sm-12' 
        },
        { 
          name: 'btn-alert', 
          useSlot: true, 
          containerClass: 'container-info col-sm-12' 
        }
      ]
    },
    ok (e) {
      e.preventDefault()
      this.$refs.formRenderStatus.checkForm()
    },
    onAccept (data) {
      let material_ids = []
      if (Array.isArray(this.material)) {
        material_ids = this.material.map(material => material.id)
      } else {
        material_ids = [this.material.id]
      }
      
      const updateData = {
        status: data.status,
        info: data.info,
        date: data.date,
        single_edit: this.singleEditing
      }

      // this.$emit('send', { form, single_edit: this.singleEditing, material_ids })
      this.updateStatus(updateData, material_ids)
    },
    updateStatus (data, material_ids) {
      const { first_name, last_name, email, role } = this.mySession
      const payload = {
        user: {
          name: `${first_name} ${last_name}`,
          email,
          role
        },
        extra_info: data.info,
        status_code: data.status.code,
        date: this.$options.filters.moment(data.date, 'YYYY-MM-DD'),
        material_ids
      }
      this.loading = true
      this.materialsService.callService('updateStatusesRequestMaterial', payload, null)
        .then(resp => {
          this.$emit('send', resp.data)
        })
        .catch(err => this.$alert(err))
        .finally(() => this.loading = false)
    }
  }
 
}
</script>

<style>
</style>