import { environment } from '@/environments/environment'
import useJwt from '@/auth/jwt/useJwt'

const services = [
  // GET Carriers
  { name: 'getCarriers', url: `${environment.puconApiUrl}/carriers`, method: 'get' },
  // GET'S Companies (Shippers / Organizations)
  { name: 'getShippers', url: `${environment.dubaiApiUrl}/shippers`, method: 'get' },
  { name: 'getOrganizations', url: `${environment.dubaiApiUrl}/organizations`, method: 'get' },
  { name: 'getShippersByOrganization', url: `${environment.dubaiApiUrl}/organizations/{organization_id}/shippers`, method: 'get' },
  { name: 'getShippersByOrganizationSimplified', url: `${environment.dubaiApiUrl}/organizations/{organization_id}/shippers?simplified=true`, method: 'get' },
  { name: 'getShippersByOrganizationPucon', url: `${environment.puconApiUrl}/organizations/{organization_id}/shippers`, method: 'get' },
  // GET Warehouses
  { name: 'getWarehousesFlash', url: `${environment.beijingApiUrl}/filters/warehouses`, method: 'get' },
  { name: 'getWarehouses', url: `${environment.ottawaApiUrl}/warehouses/{shipper_id}`, method: 'get' },
  { name: 'getWarehousesPucon', url: `${environment.puconApiUrl}/shippers/{shipper_id}/warehouses`, method: 'get' },
  { name: 'createMaterial', url: `${environment.puconApiUrl}/companies/{company_id}/materials`, method: 'post' },
  { name: 'getMaterials', url: `${environment.puconApiUrl}/materials`, method: 'get' },
  { name: 'deleteMaterials', url: `${environment.puconApiUrl}/materials`, method: 'delete' },
  // GET Countries 
  { name: 'getCountries', url: `${environment.dubaiApiUrl}/countries`, method: 'get', notCancel: true },
  // POST Export Materials
  { name: 'exportMaterials', url: `${environment.puconApiUrl}/materials/export`, method: 'post', useQueryParams: true },
  // PUT Update materials status
  // { name: 'updateStatusesRequestMaterial', url: `${environment.puconApiUrl}/materials/{material_id}/statuses`, method: 'put' },
  { name: 'updateStatusesRequestMaterial', url: `${environment.puconApiUrl}/materials/statuses`, method: 'put' }, // Endpoint masivo
  // PUT Update reference materials
  { name: 'updateReferenceCarrier', url: `${environment.puconApiUrl}/materials/{material_id}/references`, method: 'put' }
]

export default class MaterialsService {
  constructor (context) {
    this.context = context
  }
  async callService (name, queryParams = {}, params = {}) {
    const service = services.filter(service => service.name === name)[0]
    return useJwt.genericService(service, queryParams, params)
  }

  async callMultipleServices (callArray, byName = false) {
    const servicesArray = callArray.map(call => ({ service: services.filter(service => service.name === call.name)[0], ...call }))
    return useJwt.genericMultipleService(servicesArray, byName)
  }
  conditionForRoleTable (role) {
    switch (role) {
    case 'admin':
      return { service: 'getAdminMaterials', queryParams: {} }
    case 'ecommerce':
      return { service: 'getMaterials', queryParams: {} }
    case 'marketplace':
      return { service: 'getMaterials', queryParams: {} }
    }
  }
  conditionForRolFields (role) {
    const obj = {
      show_shipper: ['marketplace', 'admin', 'superadmin'].includes(role),
      show_organization_set: ['marketplace', 'ecommerce'].includes(role),
      show_checkbox: ['admin'].includes(role),
      show_actions: ['admin', 'superadmin'].includes(role),
      show_company_table: ['admin', 'marketplace'].includes(role),
      show_column_provider: ['ecommerce'].includes(role),
      set_shipper: ['marketplace'].includes(role)
    }
    return obj
  }
  /**
   * Función que saca columnas de la tabla 
   * @param {Array} columnsToExclude Arreglo de columnas a excluir de la tabla original
   */
  excludeTableFields (columnsToExclude, schema) {
    return schema.filter(column => !columnsToExclude.includes(column.key))
  }
}